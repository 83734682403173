import TableHead from '@/view/pages/posm/campaign/models/TableHead';

export const posmFormTableFields = [
  new TableHead('Tên Campaign', 'campaignName'),
  new TableHead('Tên Form', 'name'),
  new TableHead('TG bắt đầu và kết thúc', 'timeRange'),
  new TableHead('Người tạo', 'createdBy'),
  new TableHead('Cửa hàng được áp dụng', 'stores'),
  new TableHead('Chức năng', 'actions'),
];

export const importPosmFormViaExcelFields = [
  {
    key: 'category',
    label: 'Hạng mục',
    thStyle: { width: '8%' },
  },
  {
    key: 'posmName',
    label: 'Vị trí hạng mục',
    thStyle: { width: '8%' },
  },
  {
    key: 'inputType',
    label: 'Loại dữ liệu',
    thStyle: { width: '4%' },
  },
  {
    key: 'generalPosition',
    label: 'Vị trí',
    thStyle: { width: '4%' },
  },
  {
    key: 'size',
    label: 'Kích thước',
    thStyle: { width: '4%' },
  },
  {
    key: 'priority',
    label: 'Độ ưu tiên',
    thStyle: { width: '4%' },
  },
  {
    key: 'startDate',
    label: 'Thời gian bắt đầu',
    thStyle: { width: '8%' },
  },
  {
    key: 'endDate',
    label: 'Thời gian kết thúc',
    thStyle: { width: '8%' },
  },
  {
    key: 'position',
    label: 'Ghi chú',
    thStyle: { width: '14%' },
  },
  {
    key: 'isValid',
    label: 'Trạng thái',
    thStyle: { width: '6%' },
  },
  {
    key: 'message',
    label: 'Thông báo',
    thStyle: { width: '14%' },
  },
];
