import { isArray, isBoolean, trim } from 'lodash';

export default class ExcelTableRow {
  /**
   * @param {string} category
   * @param {string} posmName
   * @param {string} inputType
   * @param {string} generalPosition
   * @param {string} size
   * @param {string} priority
   * @param {string} startDate
   * @param {string} endDate
   * @param position
   * @param {array} storeHeaders
   * @param {object} item
   * @param {boolean || null} isValid
   * @param {string} message
   */
  constructor(
    category,
    posmName,
    inputType,
    generalPosition,
    size,
    priority,
    startDate,
    endDate,
    position,
    storeHeaders,
    item,
    isValid,
    message,
  ) {
    this.category = trim(category) || '';
    this.posmName = trim(posmName) || '';
    this.inputType = trim(inputType) || '';
    this.generalPosition = trim(generalPosition) || '';
    this.size = trim(size) || '';
    this.priority = trim(priority) || '';
    this.startDate = trim(startDate) || '';
    this.endDate = trim(endDate) || '';
    this.position = trim(position) || '';
    this.storeHeaders =
      isArray(storeHeaders) && storeHeaders.length > 0 ? storeHeaders : [];
    this.isValid = isBoolean(isValid) || null;
    this.message = trim(message) || '';

    if (storeHeaders) {
      storeHeaders.forEach((storeHeader) => {
        for (const key in item) {
          if (key === storeHeader) {
            this[trim(storeHeader)] = item[key];
          }
        }
      });
    }
  }
}
